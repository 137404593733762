import {LockClosedIcon} from '@heroicons/react/solid';
import {PageHeader} from '@components/page-header';
import {PageFooter} from '@components/page-footer';
import {Button} from '@components/button';
import {Field, Form, Formik} from 'formik';
import * as yup from 'yup'
import {getSession, signIn, useSession} from 'next-auth/react';
import {Logo} from '@components/logo';
import {GetServerSideProps} from 'next';
import {useRouter} from 'next/router';

const initialValue = {
    username: '',
    password: ''
}

const formSchema = yup.object({
    username: yup.string().email().required(),
    password: yup.string().required()
})

const GoogleIcon = () => (
    <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
            <path fill="#4285F4"
                  d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
            <path fill="#34A853"
                  d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
            <path fill="#FBBC05"
                  d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
            <path fill="#EA4335"
                  d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
        </g>
    </svg>
)

const FacebookIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.29 11.7152C23.29 5.24456 18.0764 -0.000936508 11.645 -0.000936508C5.21364 -0.000936508 0 5.24456 0 11.7152C0 17.5631 4.25841 22.4101 9.82547 23.2891V15.1019H6.86873V11.7152H9.82547V9.13401C9.82547 6.19765 11.564 4.57569 14.224 4.57569C15.498 4.57569 16.8307 4.80452 16.8307 4.80452V7.6878H15.3623C13.9157 7.6878 13.4645 8.59092 13.4645 9.51746V11.7152H16.6942L16.1779 15.1019H13.4645V23.2891C19.0316 22.4101 23.29 17.5631 23.29 11.7152Z"
            fill="white"/>
    </svg>

)

const errors: any = {
    Signin: 'Royn at rita inn við einum øðrum møguleika',
    OAuthSignin: 'Royn at rita inn við einum øðrum møguleika',
    OAuthCallback: 'Royn at rita inn við einum øðrum møguleika',
    OAuthCreateAccount: 'Royn at rita inn við einum øðrum møguleika',
    EmailCreateAccount: 'Royn at rita inn við einum øðrum møguleika',
    Callback: 'Royn at rita inn við einum øðrum møguleika',
    OAuthAccountNotLinked:
        'Fyri at vátta tín samleika, vinarliga rita inn við tí sama møguleika tú hevur nýtt áður.',
    EmailSignin: 'Vinarliga eftirkanna teldupostaddressuna',
    CredentialsSignin:
        'Ein villa er íkomin. Vinarliga vátta at upplýsingarnir eru rættir',
    default: 'Ein villa er íkomin. Vinarliga royn aftur seinni.',
};
const SignInError = ({error}: any) => {
    const errorMessage = error && (errors[error] ?? errors.default);
    return <div>{errorMessage}</div>;
};

const LoginPage = () => {
    const router = useRouter()
    const {returnTo,intent} = router.query
    const {error} = useRouter().query;
    const session = useSession();
    const googleAuth = async () => {
        const {referralId} = router.query
        await signIn('google', returnTo ? {callbackUrl: `${window.location.origin}/api/return?to=${router.query['returnTo']}&intent=${intent}&referralId=${referralId}`} : undefined)
    }
    const facebookAuth = async () => {
        const {referralId} = router.query
        await signIn('facebook', returnTo ? {callbackUrl: `${window.location.origin}/api/return?to=${router.query['returnTo']}&intent=${intent}&referralId=${referralId}`} : undefined)
    }
    return (
        <div className="h-screen w-screen bg-primary-500 grid place-items-center ">
            <div className="max-w-screen-md px-4 py-8">
                <div className="bg-white p-4 rounded-md shadow flex w-full max-w-sm flex-col items-center">
                    <div className="border-b pb-4 w-full flex justify-center">
                        <Logo badgeOnly/>
                    </div>
                    <div className="text-primary-500 text-center px-8 my-8">
                        {error ? <SignInError error={error}/> : (<>
                            Áðrenn tú kanst reyta, mást tú rita inn.
                            Vel ein av møguleikunum niðanfyri.
                        </>)}
                    </div>
                    <div className="space-y-4 px-8 flex flex-col mb-12 w-full">
                        <button onClick={facebookAuth}
                                className="rounded-md bg-[#1877F2] hover:bg-opacity-90 transition-colors flex items-center px-4 py-3 font-bold font-alt text-white">
                            <div className="mr-4"><FacebookIcon/></div>
                            Rita inn við Facebook
                        </button>
                        <button onClick={googleAuth}
                                className="rounded-md border border-[#C9CEDF] px-4 py-3 transition-colors hover:bg-gray-100 text-primary-500 flex items-center font-bold font-alt">
                            <div className="mr-4"><GoogleIcon/></div>
                            Rita inn við Google
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

// noinspection JSUnusedGlobalSymbols
export const getServerSideProps: GetServerSideProps<any> = async (context) => {
    const session = await getSession(context)
    if (session?.user) {
        return {
            redirect: {
                destination: '/',
                permanent: false
            }
        }
    } else {
        return {props: {}}
    }
}

export default LoginPage;

